import React from "react";

export default function Contact() {
  return (
    <>
      <section id='contact' className='contact'>
        <h2 className='section-title'>Contact</h2>
        <p className='contact-text'>→ Let’s make something together </p>
        <a href='mailto:web@juanfalibene.com' className='contact-button'>
          web@juanfalibene.com
        </a>
      </section>
    </>
  );
}
